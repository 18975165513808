.paper {
  background-color: #263238;
  width: 225px;
  align-items: center;
  padding: 20px 5px;
  padding-top: 70px;
  border-right: none;
  color: #fff;

  &Mobile {
    @extend .paper;
    padding-top: 20px;
  }
}

.userWrapper {
  display: flex;
  align-items: center;
}

.avatarWrapper {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0 4px;
  flex-shrink: 0;
}

.avatar {
  background-color: #7e57c2;
  height: 100%;
  width: 100%;
  font-size: 12px;
  z-index: 1;
}

.settings {
  position: absolute;
  bottom: -5px;
  right: 0;
  z-index: 2;
}

.userName {
  font-size: 14px;
  font-weight: bold;
  margin: 0 4px;
  flex-grow: 1;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}

.user {
  padding: 20px 10px 10px;
  background-color: #37474f;
  width: 90%;
  color: #fff;
}

.authState {
  font-style: oblique;
  text-transform: capitalize;
  opacity: 0.5;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.link {
  text-decoration: none;
  color: inherit;
}

.list {
  width: 100%;
}

.icon {
  color: #fff;
  margin-left: 8px;
}

.nested {
  padding-left: 30px;
  max-width: 210px;
  overflow: hidden;
}

.departmentSelector {
  color: #fff;
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
}


.text {
  white-space: nowrap;
  max-width: 120px;
  overflow: visible;
}

.columnFlex {
  display: flex;
  flex-direction: column;
}

.drawer {
  position: absolute;
  right: -50px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 50px;
  height: 50px;
}
