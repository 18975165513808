.title {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.content {
  min-height: calc(100vh - 193px);
  overflow-x: hidden;
}

.contentEmbedded {
  overflow-x: hidden;
}

.input {
  display: none;
}

.avatar {
  background-color: #7e57c2;
  margin-right: 10px;
}

.downloadLink {
  color: #7e57c2;
  text-decoration: none;
  cursor: pointer;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  // margin-left: 20px;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.listItemText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.filename {
  margin-left: 5px;
}

.buttonWrapper {
  position: relative;
}

.buttonNumber {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  font-size: 12px;
}

.image {
  max-width: 100%;
  margin-top: 20px;
  padding-left: 50px;
}

.flex {
  display: flex;
  align-items: center;
}

.uploadbtn {
  margin-top: 1em;
}

.filter {
  margin-right: 16px;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.disabled {
  filter: opacity(50%);
}

.mainButton {
  min-width: 105px;
}
