.button {
  min-width: 81px;
}

.title {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.metricInput {
  display: flex;
}

.unit {
  margin-left: 10px;
  max-width: 150px;
}

.modelButtons {
  text-align: center;
}

.toggleText {
  font-size: 16px;
  text-transform: none;
}

.referenceItem {
  max-width: 500px;
  display: flex;
  align-items: center;
}
.referenceItemText {
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
