@keyframes rotateOne {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotateTwo {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotateThree {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.fullscreen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  perspective: 800px;
  margin: 0 auto;
}

.base {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.one {
  @extend .base;
  left: 0;
  top: 0;
  animation: rotateOne 1s linear infinite;
  border-bottom: 3px solid #7e57c2;
}

.two {
  @extend .base;
  right: 0;
  top: 0;
  animation: rotateTwo 1s linear infinite;
  border-right: 3px solid #7e57c2;
}

.three {
  @extend .base;
  right: 0;
  bottom: 0;
  animation: rotateThree 1s linear infinite;
  border-top: 3px solid #7e57c2;
}
