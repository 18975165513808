.label {
  opacity: 0.65;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.flex {
  display: flex;
  align-items: center;
}
