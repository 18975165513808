.button {
  min-width: 81px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.stepsContainer {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0 8px;

  &:hover {
    border-color: black;
  }

  .inputLabel {
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    position: absolute;
    top: -10px;
    color: rgba(0, 0, 0, 0.54);
    background-color: white;
  }

  .stepsListItem {
    padding-left: 6px;
  }
}

.avatar {
  height: 25px;
  width: 25px;
  text-transform: uppercase;
  font-size: 12px;
}
