.button {
  min-width: 81px;
}

.title {
  display: flex;
  justify-content: space-between;
}
  
.highlightToday {
  background-color: #3f51b5; 
  color: white;
  border-radius: 50%;
}
