.container {
  min-height: calc(100vh - 120px);
}

.header {
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  font-size: 22px;
  text-transform: uppercase;
  padding: 3px 0;
}

.headerName {
  input {
    font-size: 22px;
    text-transform: uppercase;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid gray;
}

.treeContainer {
  height: calc(100vh - 270px);
  width: 100%;
}

.nodeTitleText {
  font-size: 12px;
  padding: 3px 0;
  color: #888888;
  font-weight: 500;
  width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nodeTitleInput {
  input {
    font-size: 12px;
    color: #888888;
    font-weight: 500;
    width: 230px;
  }
}

.nodeNameText {
  font-size: 14px;
  padding: 3px 0;
  color: #707070;
  font-weight: 700;
  width: 230px;
  text-overflow: ellipsis;
}

.nodeNameInput {
  input {
    font-size: 14px;
    color: #707070;
    font-weight: 700;
    width: 230px;
  }
}

.nodeRolesText {
  font-size: 12px;
  padding: 3px 0;
  color: #919191;
}

.nodeRolesInput {
  input {
    font-size: 12px;
    color: #919191;
    width: 230px;
  }
}

.nodeListText {
  height: 140px;
  overflow-y: auto;
  font-size: 12px;
  padding: 3px 16px;
  margin-top: 0;
  margin-bottom: 0;
  color: #444;
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
  }
}

.nodeListName {
  input {
    font-size: 12px;
    color: #444;
  }
}

.nodeCard {
  background-color: #f4f4f4;
}

.nodeCardAvatar {
  stroke-width: 0;
  margin: 0 0 0 16px;
}

.nodeHeaderTextContainer {
  display: flex;
  align-items: center;
}

.nodeCardContent {
  padding-bottom: 0;
  padding-top: 0;
}

.nodeCardAction {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  padding-top: 0;
}
