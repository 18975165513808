.tableContainer {
  margin: 4px 0;
  overflow-x: hidden;
}

.tableHead {
  background-color: #7e57c2;
  border: 1px solid white;

  th {
    color: white;
  }
}

.tableBody {
  > tr {
    background-color: #e2daf1;
  }

  > tr:nth-child(even) {
    background-color: #c6b4e4;
  }

  td {
    padding: 6px;
    border: 1px solid white;

    .gradesOuter {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 6px;

      .gradesInner {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;

        .plansContainer {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          width: 50%;
          gap: 6px;
        }

        .statsContainer {
          display: flex;
          text-align: left;
          justify-content: flex-start;
          flex-direction: column;
          width: 50%;

          &Solo {
            @extend .statsContainer;
            width: 100%;
            text-align: center;
            margin: 0;
          }
        }
      }
    }
  }
}

.input {
  display: none;
}

.filename {
  margin-left: 5px;
}

.listItemText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.avatar {
  background-color: #7e57c2;
  margin-right: 10px;
}

.downloadLink {
  color: #7e57c2;
  text-decoration: none;
  cursor: pointer;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  // margin-left: 20px;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.image {
  max-width: 100%;
  margin-top: 20px;
  padding-left: 50px;
}
