.container {
  position: relative;
  width: 175px;
  height: 24px;
  color: #fff;
  background-color: #78909c;
  padding: 3px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  &Rocks {
    @extend .container;
    width: 60px;
    padding: 3px 0;
    border-radius: 5px;
    margin: 0 1px;

    &Alt {
      @extend .containerRocks;
      background-color: #a98fd6;
    }
  }
}

.bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #263238;
  border-radius: 20px;
  z-index: 1;
  transition: width 0.3s ease-out;
  &Rocks {
    @extend .bar;
    border-radius: 5px;

    &Alt {
      @extend .barRocks;
      background-color: #7044bb;
    }
  }
}

.text {
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  white-space: nowrap;
}
