.button {
  cursor: pointer;
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.menu {
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
  position: relative;
  padding: 10px 10px 0px 10px;
  display: inline-block;
}

.wrapper {
  max-width: 100%;
  margin: 0px auto;
  // padding: 10px;

  p {
    margin: 0;
  }

  pre {
    padding: 10px;
    background-color: #eee;
    white-space: pre-wrap;
  }

  :not(pre) > code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
  }

  img {
    max-width: 100%;
    max-height: 20em;
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  blockquote[dir="rtl"] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid #ddd;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 10px;
    border: 2px solid #ddd;
  }

  input {
    box-sizing: border-box;
    font-size: 0.85em;
    width: 100%;
    padding: 0.5em;
    border: 2px solid #ddd;
    background: #fafafa;
  }

  input:focus {
    outline: 0;
    border-color: blue;
  }

  iframe {
    width: 100%;
    border: 1px solid #eee;
  }

  [data-slate-editor] > * + * {
    margin-top: 1em;
  }

  ul {
    list-style-type: disc;
  }
}

.edit {
  padding: 10px;
}

.read {
  padding: 0px 0 0 50px;
}

.elementLink {
  display: inline;
  position: relative;
}

.elementLink .popup {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.elementLink .popup a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 10px;
  border-right: 1px solid lightgrey;
}

.elementLink .popup button {
  border: none;
  background: transparent;
}

.elementLink .popup button:hover {
  color: #673ab7;
  cursor: pointer;
}
