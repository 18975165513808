.icon {
  margin-bottom: 10px;
  margin-left: auto;
  color: #fff;
}

.listCard {
  background-color: #f4f4f4;
  cursor: pointer;
}
