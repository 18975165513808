.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.flex {
  display: flex;
  align-items: center;
  &Between {
    @extend .flex;
    justify-content: space-between;
  }
}
