.emphasis {
  font-weight: 700;
  text-transform: capitalize;
}

.label {
  opacity: 0.65;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.flex {
  display: flex;
  align-items: center;
  &Between {
    @extend .flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.item {
  display: flex;
  justify-content: space-between;
}

.formControl {
  margin: 4px;
}

.select {
  width: 130px;
  &Long {
    @extend .select;
    width: 200px;
  }
}

.views {
  max-width: 100%;
  overflow-x: auto;
  justify-self: end;
  display: flex;
  margin-bottom: 10px;
}

.menuItemLong {
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.center {
  text-align: center;
}

.yearDisplay {
  width: 100%;
}

.filterButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
