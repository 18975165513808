.departmentName {
  font-style: oblique;
  opacity: 0.65;
}

.disabled {
  opacity: 0.65;
}

.past {
  background-color: rgba(0, 0, 0, 0.065);
  opacity: 0.65;
  font-style: oblique;
}

.current {
  background-color: rgba(126, 87, 194, 0.2);
}

.card {
  position: relative;
  overflow: visible;
  min-height: 282px;
}

.title {
  text-transform: capitalize;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;   
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
}

.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;
  // max-height: 75vh;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.icon {
  margin-bottom: 10px;
  &Left {
    margin-left: auto;
    background-color: #66bb6a;
    &:hover {
      background-color: #43a047;
    }
  }
}

.dndInfo {
  color: #7e57c2;
  flex: 1;
}

.table {
  position: relative;

  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;

    &:first-child {
      padding-left: 50px;
      padding-right: 0px;
      width: 65px;
    }

    &.value {
      min-width: 350px;
    }
  }

  &Row {
    background-color: #fff;
  }

  .backdrop {
    position: absolute;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: wait;
    display: block;
    background-color: transparent;

    > .loadingContainer {
      position: sticky;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.lastCell {
  // Moves the cell all the way to the right of the table
  width: 1px;
}

.delete {
  color: #ef5350;
}

.textFields {
  margin-right: 20px;
  flex: 1;
}

.flex {
  display: flex;
  align-items: center;
  &Column {
    @extend .flex;
    flex-direction: column;

    &Right {
      @extend .flexColumn;
      justify-content: right;
    }    
  }
  &Center {
    @extend .flex;
    justify-content: center;
  }
}

.noBot {
  border-bottom: none;
}

.skeletonRow {
  height: 50px;
}

.overdue {
  margin-right: 5px;
}

.auto {
  margin: 16px auto 0;
}

.red {
  color: red;
  font-weight: bold;
}

.green {
  color: green;
  font-weight: bold;
}

.metricListItem {
  padding-left: 0;
  padding-right: 8px;
}

.modelButtons {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.referenceText {
  margin-right: 20px;
  max-height: 70px;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;
}

.distributionContainer {
  display: flex;
  align-items: center;

  & div {
    margin-right: 8px;
    max-width: 90px;
  }
}

.distributionTotal {
  width: 100px;
  margin-top: 8px;
  margin-left: auto;
  padding: 0px 16px;
  font-weight: bold;
}

.buttonText {
  margin-left: 9px;
  margin-right: auto;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px;
}
.toggleText {
  font-size: 16px;
  text-transform: none;
}
