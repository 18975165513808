/*.avatar {
    width: 70px;
    height: 70px;
    font-size: 50px;
    margin-right: 0px;
    margin-bottom: 5px;
 }

 .userContainer {
    font-size: 50px;
    margin-right: 15px;
    margin-bottom: 20px;
 }
*/

.avatar {
   width: 70px;
   height: 70px;
   font-size: 50px;
   margin-right: 0px;
   margin-bottom: 10px;
}

.userContainer {
   font-size: 50px;
   margin-right: 15px;
   margin-bottom: 10px;
   margin-top: 10px;
   width: 100px; /* Fixed width for uniformity */
   height: 120px; /* Fixed height for uniformity */
   display: flex;
   flex-direction: column;
   justify-content: flex-start; /* Ensure name and avatar are spaced nicely */
   align-items: center;
}

.userContainer .MuiTypography-body2 {
   font-size: 14px;
   text-align: center;
   width: 100%; /* Ensure text fits inside the container */
   white-space: nowrap; /* Prevent text wrapping */
   overflow: hidden; /* Hide overflowing text */
   text-overflow: ellipsis; /* Add "..." for truncated text */
}
