.reactMarkdown {
  * {
    font-family: "Arial Narrow", sans-serif;
    font-weight: normal;
  }
  h1 {
    margin: 0;
  }
  h2 {
    color: #553a7d;
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 0;
  }
  b {
    font-weight: 700;
  }
  p {
    margin-top: 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 540px;
    margin: auto;
  }
  td,
  th {
    border: 1px solid #fff;
    background-color: #553a7d;
    color: #fff;
    text-align: center;
    padding: 8px;
  }
  ul {
    list-style: none;
  }
  ul li:before {
    content: "";
    background-color: #553a7d;
    font-weight: bold;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    border-radius: 99px;
  }
}
