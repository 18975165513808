.container {
  min-width: 90px;
  display: flex;
  justify-content: center;
}

.wrapper {
  position: relative;
  display: inline-flex;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #fff;
}

.listIcon {
  min-width: 35px;
  flex-direction: column;
  align-items: center;
}
