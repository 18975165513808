.text {
  color: rgba(0, 0, 0, 0.65);
}

.carousel {
  position: relative;
  overflow: hidden;
  height: 55px;

  .wrapper {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1.2);
  }
  span {
    height: 45px;
    margin-bottom: 45px;
    display: block;
  }
}

.card {
  position: relative;
  text-align: center;
  margin: 16px;
  padding: 16px;
  width: 200px;
  color: #fff;
  &Red {
    @extend .card;
    background-color: #ef5350;
  }
  &Purple {
    @extend .card;
    background-color: #ab47bc;
  }
  &Blue {
    @extend .card;
    background-color: #42a5f5;
  }
}

.cardIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.15;
  pointer-events: none;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -10px;
}
