.button {
  min-width: 81px;
}
.metricInput {
  display: flex;
}

.unit {
  margin-left: 10px;
  max-width: 150px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.modelButtons {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.field {
  margin-right: 20px;
  flex: 1;
}

.buttonText {
  margin-left: 9px;
  margin-right: auto;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px;
}

.toggleText {
  font-size: 16px;
  text-transform: none;
}
