.button {
  min-width: 81px;
}

.title {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.modelButtons {
  text-align: center;
}

.tieInItem {
  max-width: 500px;
  display: flex;
  align-items: center;
}
.tieInItemText {
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.searchField {
  margin: 4px;
}
