.logoContainer {
  color: #ffffff;
  width: 100%;
  padding: 20px 10px 20px;
}

.logo {
  max-width: 116px;
  width: 116px;
  margin: 0 auto;
  display: block;
}

.link {
  display: block;
  margin-top: 1rem;
}

.warning {
  color: red;
  margin-left: 1rem;
}

.button {
  margin-top: 8px;
}
