.label {
  opacity: 0.65;
  &Padded {
    @extend .label;
    margin-left: 16px;
  }
}

.flex {
  display: flex;
  align-items: center;
  &End {
    @extend .flex;
    justify-content: flex-end;
  }
  &Paragraph {
    @extend .flex;
    margin-bottom: 16px;
  }
  &Between {
    @extend .flex;
    justify-content: space-between;
  }
}

.body {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.87);
}

.divider {
  margin-top: 16px;
}

.editButton {
  margin-left: auto;
  text-align: right;
}

.placeholder {
  text-transform: capitalize;
}

.draggable {
  cursor: grab;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
  // align-items: stretch;
}