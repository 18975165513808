.label {
  opacity: 0.65;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 300px;
  &NoTop {
    display: flex;
    justify-content: center;
  }
}

.centerButton {
  display: flex;
  justify-content: center;
}

.description {
  width: 100%;
}

.button {
  margin: 10px;
}

.info {
  display: flex;
  align-items: center;
  &Sc {
    padding-top: 20px;
  }
  span {
    margin-right: 10px;
  }
}

.buttons {
  display: flex;
  button {
    margin-right: 10px;
  }
}

.complete {
  color: #81c784;
}

.incomplete {
  color: #e57373;
}

.grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: flex-start;
}

.listItemIcon {
  cursor: pointer;
}
