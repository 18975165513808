.button {
  min-width: 133px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.label {
  opacity: 0.65;
}
