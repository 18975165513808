.card {
  position: relative;
  overflow: visible;
  padding-top: 0;
  @media screen and (max-width: 600px) {
    margin: 10px 0;
  }
}

.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.icon {
  font-size: 75px;
}

.marginBottom {
  margin-bottom: 20px;
}

.marginTop {
  margin-top: 40px;
}

.cardActions {
  justify-content: flex-end;
}

.profilePicture {
  position: absolute;
  width: 105px;
  height: 105px;
  left: 25px;
  top: 0;
  transform: translateY(-25%);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.editButton {
  position: absolute;
  left: 45px;
  top: 50px;
  button {
    color: #fff;
  }
}

.input {
  display: none;
}

.delete {
  color: #ef5350;
}

////// HERE

.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
  &Padded {
    @extend .label;
    margin-left: 16px;
  }
}

.card {
  position: relative;
  overflow: visible;
  padding-top: 50px;
  color: #fff;
  margin: 30px 0;
}

.subtitle {
  opacity: 0.65;
  margin-left: 10px;
  font-style: oblique;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.body {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.87);
}

.icon {
  margin: 0 10px 0 0;
}

.placeholder {
  text-transform: capitalize;
}

.flex {
  display: flex;
  align-items: center;
  &Paragraph {
    @extend .flex;
    margin-bottom: 10px;
  }
}

.textField {
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 500px;
}

.avatar {
  width: 150px;
  height: 150px;
  font-size: 100px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.divider {
  margin-top: 20px;
}

.editButton {
  margin-left: auto;
}

.avatarWrapper {
  position: relative;
}

.editAvatar {
  position: absolute;
  bottom: 0;
  left: 42px;
  button {
    color: #fff;
  }
}

.input {
  display: none;
}

.delete {
  color: #ef5350;
}

.saveButton {
  display: block;
  margin-left: auto;
}

.notifications {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 500px;
}
