.label {
    opacity: 0.65;
  }
  
  .button {
    text-transform: lowercase;
  }
  
  .input {
    display: hidden;
    pointer-events: none;
    opacity: 0;
  }
  
  .delete {
    color: #ef5350;
  }
  
  .card {
    position: relative;
    overflow: visible;
    min-height: 100px;
  }
  
  .title {
    text-transform: capitalize;
  }
  
  .left {
    margin-left: auto;
    &Icon {
      @extend .left;
      background-color: #26a69a;
      &:hover {
        background-color: #00897b;
      }
    }
  }
  
  .cardContent {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .table {
    th,
    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  
  .firstCell {
    padding-left: 50px;
    width: 1px;
  }
  
  .email {
    color: inherit;
  }
  
  .auth {
    text-transform: capitalize;
  }
  
  .flex {
    display: flex;
    align-items: center;
  }
  
  .fileUpload {
    display: flex;
    align-items: center;
    & label {
      cursor: pointer;
    }
    & > div {
      margin-left: 16px;
    }
  }
  