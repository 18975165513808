.container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #673ab7;
  overflow: hidden;
}

.splash {
  &Overlay {
    display: flex;
    align-items: center;
    padding: 10px;
    img {
      height: 50px;
      margin-right: 20px;
    }
  }

  &Title {
    color: #fff;
    font-weight: 700;
  }
}

.svg {
  position: absolute;
  width: 95%;
  max-width: 1100px;
  top: 75px;
  left: 0;
  @media screen and (max-width: 960px) {
    width: auto;
    height: 100%;
    left: -100px;
    top: 150px;
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 600px;
  height: 100%;
  border-radius: 20px 0 0 20px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    height: 550px;
  }
}

.flex {
  display: flex;
  gap: 10px;
  width: 100%;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cardContainer {
  position: relative;
  margin: 10px 0;
  width: 100%;

  &Inactive {
    @extend .cardContainer;
    opacity: 0.65;
  }
}

.link {
  text-decoration: none;
}

.card {
  cursor: pointer;
}

.buttons {
  z-index: 2;
  position: absolute;
  bottom: 12.5px;
  right: 12.5px;
}

.fiscalYear {
  max-width: 55%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media screen and (max-width: 960px) {
    span {
      display: none;
    }
  }
}

.auth {
  text-transform: capitalize;
}

.logoutBtn {
  color: #ef5350;
  padding-bottom: 16px !important;
}

.createBtn {
  color: #673ab7;
  padding-bottom: 16px !important;
}
