.chip {
  margin: 5px;
}

.complete {
  @extend .listItem;
  text-decoration: line-through;
}

.list {
  position: relative;
  border: solid 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 4px 0;
  width: 17vw;
  min-width: 300px;
  background-color: #fff;
  &Dragging {
    @extend .list;
    background: #d1c4e9;
    border: solid 2px #9575cd;
  }

  &Complete {
    background-color: lightgray;
  }

  &.hasRockProgress {
    padding-top: 20px;
  }
}

.listIcon {
  min-width: 35px;
}

.cursor {
  cursor: pointer;
}

.listItem {
  opacity: 0.75;
  cursor: pointer;

  &NoRock {
    @extend .listItem;
    font-style: oblique;
  }
}

.delete {
  color: #ef5350;
}

.rockProgress {
  text-decoration: none !important;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 16px;
  z-index: 2;
  background-color: #7e57c2;
  border-radius: 2px 0 0 0;
  transition: all 0.3s;
  pointer-events: none;

  &.rpComplete {
    border-radius: 2px 2px 0 0;
  }
}

.label {
  opacity: 0.65;
  font-style: oblique;
}

.msContainer {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0 8px;

  &:hover {
    border-color: black;
  }

  .inputLabel {
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    position: absolute;
    top: -10px;
    color: rgba(0, 0, 0, 0.54);
    background-color: white;
  }

  .msListItem {
    padding-left: 6px;
  }
}

.targetDate {
  color: #7e57c2;

  &Past {
    @extend .targetDate;
    color: red;
  }
}

.droppable {
  &Dragging {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background: rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "+";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 100px;
      font-weight: 500;
      z-index: 3;
    }
  }
}

.dragHandle {
  position: absolute;
  left: 0;
  top: 20;
  z-index: 2;
}

.flex {
  display: flex;
  align-items: center;
  &Center {
    @extend .flex;
    justify-content: center;
  }
  &Relative {
    @extend .flex;
    justify-content: center;
    position: relative;
  }
}

.tieInItem {
  max-width: 500px;
  display: flex;
  align-items: center;
}
.tieInItemText {
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}