.card {
  position: relative;
  overflow: visible;
  min-height: 177px;
}

.title {
  text-transform: capitalize;
}

.label {
  opacity: 0.65;
}

.quarter {
  white-space: nowrap;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
  // align-items: stretch;
}

.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;
  // max-height: 74vh;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.icon {
  margin-bottom: 10px;
  &Left {
    margin-left: auto;
    background-color: #42a5f5;
    &:hover {
      background-color: #1e88e5;
    }
  }
}

.menuButton {
  margin: 4px;
  &Color {
    margin: 4px;
    background-color: #42a5f5;
    &:hover {
      background-color: #1e88e5;
    }
  }
}

.table {
  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  tr td:first-child {
    padding-left: 50px;
  }
}

.firstCell {
  padding-left: 50px;
}

.secondCell {
  min-width: 400px;

  @media screen and (max-width: 1920px) {
    max-width: 25vw;
  }
}

.lastCell {
  // Moves the cell all the way to the right of the table
  width: 1px;
}

.delete {
  color: #ef5350;
}

.flex {
  display: flex;
  align-items: center;
  &Wrap {
    @extend .flex;
    flex-wrap: wrap;
  }
  &Center {
    @extend .flex;
    justify-content: center;
  }
}

.departmentName {
  font-style: oblique;
  opacity: 0.65;
}

.chip {
  margin: 5px;
}

.scComplete {
  @extend .chip;
  text-decoration: line-through;
}

.noBot {
  td {
    border-bottom: none;
  }
}

.noSc {
  font-style: oblique;
  opacity: 0.65;
  u {
    cursor: pointer;
  }
}

.complete {
  text-decoration: line-through;
}

.skeletonRow {
  height: 50px;
}

.selectShowHiddenRocks {
  min-width: 110px;
  margin: 4px;
}

.selectShowSc {
  min-width: 110px;
  margin: 4px;
}

.selectQuarterForm {
  min-width: 75px;
  margin: 4px;
}

.searchField {
  margin: 4px;
}

.containerPadding {
  padding: 4px 10px 4px 0;
}

.total {
  font-weight: 700;
  color: #fff;
  background-color: #263238;
  padding: 6px 10px;
  margin-right: auto;
  margin-left: 8px;
  border-radius: 20px;
}
