.label {
  opacity: 0.65;
}

.delete {
  color: #ef5350;
}

.complete {
  opacity: 1;
  color: #66bb6a;
}

.incomplete {
  opacity: 1;
  color: #ef5350;
}

.icon {
  margin-left: 10px;
  &Done {
    min-width: 35px;
  }
}

.container {
  position: relative;
  border: solid 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 250px;
}

.done {
  text-decoration: line-through;
}

.flex {
  display: flex;
  align-items: center;
}
