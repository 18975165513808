.avatar {
  text-transform: uppercase;
  background-color: #7e57c2;
  font-size: 14px;
  &None {
    @extend .avatar;
    background-color: #ef5350;
    &Small {
      @extend .avatarNone;
      @extend .avatarSmall;
    }
  }
  &Remain {
    @extend .avatar;
    background-color: #442fce;
    margin-left: -4px;
    &Small {
      @extend .avatarRemain;
      @extend .avatarSmall;
    }
  }

  &Small {
    @extend .avatar;
    height: 25px;
    width: 25px;
    font-size: 12px;
  }
}

.center {
  display: flex;
  justify-content: center;
}
