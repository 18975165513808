.card {
  position: relative;
  overflow: visible;
  min-height: 318px;
}

.title {
  text-transform: capitalize;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;   
}

.icon {
  margin-bottom: 10px;

  &Left {
    margin-left: auto;
    background-color: #ffa726;

    &:hover {
      background-color: #fb8c00;
    }
  }

  &LeftNoColor {
    margin-left: auto;
    width: max-content;
  }
}

.buttonActive {
  background-color: rgba(0, 0, 0, 0.1);
}

.label {
  opacity: 0.65;
  text-transform: capitalize;
  margin-left: 5px;
}

.hidden {
  font-weight: 300;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 55px;
}

.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;

  // max-height: 75vh;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.departmentName {
  font-style: oblique;
  opacity: 0.65;
}

.description {
  font-style: normal;
}

.dndInfo {
  color: #7e57c2;
  flex: 1;
}

.table {
  position: relative;

  > thead > tr > th,
  > tbody > tr > td {
    padding-top: 2px;
    padding-bottom: 2px;

    &:first-child {
      padding-left: 50px;
      padding-right: 0px;
    }

    &.noPadding {
      padding: 0;
    }
  }

  &Row {
    background-color: #fff;
  }

  .backdrop {
    position: absolute;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: wait;
    display: block;
    background-color: transparent;

    > .loadingContainer {
      position: sticky;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.lastCell {
  // Moves the cell all the way to the right of the table
  width: 1px;
}

.footer {
  & td {
    // uncomment the next two lines to make footer sticky
    // bottom: 0;
    // position: sticky;
    padding-top: 16px;
  }
}

.delete {
  color: #ef5350;
}

.complete {
  text-decoration: line-through;
}

.flex {
  display: flex;
  align-items: center;

  &SpaceBetween {
    @extend .flex;
    justify-content: space-between;
  }

  &Center {
    @extend .flex;
    justify-content: center;
  }
}

.noBot {
  td {
    border-bottom: none;
  }
}

.oneYearItem {
  margin: 0 20px;
}

.rocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  min-width: 300px;
}

.rocksList {
  display: flex;
  justify-content: space-between;
}

.divider {
  margin: 0 20px 20px;
}

.rocksCompleteText {
  margin-left: 3px;
  margin-right: 10px;
  font-weight: 700;
}

.quarter {
  &Current {
    color: #673ab7;
  }

  &Complete {
    // color: rgba(0, 0, 0, 0.4);
    opacity: 0.65;
  }
}

.skeletonRow {
  height: 50px;
}

.droppable {
  padding: 10px;
  width: 25%;

  &Dragging {
    @extend .droppable;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background: rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "+";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 100px;
      font-weight: 500;
      z-index: 3;
    }
  }
}

.menuButton {
  margin: 4px;

  &Color {
    margin: 4px;
    background-color: #ffa726;

    &:hover {
      background-color: #fb8c00;
    }
  }
}

.searchField {
  margin: 4px;
}

.filterFormControl {
  margin: 8px 4px;

  .filterSelect {
    min-width: 90px;
  }
}
