

.listCard {
  background-color: #f4f4f4;
}


.label {
  opacity: 0.8;
  &MarginRight {
    @extend .label;
    margin-right: 10px;
  }
  &MarginTop {
    @extend .label;
    margin-top: 30px;
  }
  &Oblique {
    @extend .label;
    font-style: oblique;
  }
  &Organizer {
    @extend .label;
    font-style: oblique;
    margin-right: 10px;
  }
  &EndMeeting {
    @extend .label;
    background-color: #000000;
    color: white;
    overflow-x: hidden;
    &:hover {
      background-color: #ef5350;
    }
  }
}


.flex {
  display: flex;
  align-items: center;
  &Paragraph {
    @extend .flex;
    margin-bottom: 16px;
  }
  &SpaceBetween {
    @extend .flex;
    justify-content: space-between;
  }

  &Center {
    @extend .flex;
    justify-content: center;
  }

  &CenterWrap {
    @extend .flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &PaddingRight {
    @extend .flex;
    padding-right: 10px;
  }
}

.parapgraph {
  margin-bottom: 16px;
}

.check {
  margin-left: 5px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  margin-left: 10px;
  &Blue {
    @extend .button;
    background-color: #42a5f5;
    &:hover {
      background-color: #1e88e5;
    }
  }
  &Red {
    @extend .button;
    background-color: #ef5350;
    &:hover {
      background-color: #e53935;
    }
  }
  &Purple {
    @extend .button;
    background-color: #ab47bc;
    &:hover {
      background-color: #8e24aa;
    }
  }
}

.avatar {
  margin-right: 15px;
}

.timer {
  display: inline-block;
  min-width: 40px;
}

.previousMeetings {
  max-height: 265px;
  overflow: hidden;
  overflow-y: auto;
}

.newMeeting {
  text-align: center;
}

.status {
  text-transform: capitalize;
  &Purple {
    @extend .status;
    color: #673ab7;
  }
}

.list {
  @media only screen and (max-width: 960px) {
    display: flex;
    overflow: auto;
    li {
      white-space: nowrap;
    }
  }
  &Container {
    width: 100%;
    margin-top: 50px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.objectiveCard {
  margin: 20px 0;
  &List {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.divider {
  margin-top: 16px;
  &Vertical {
    margin: 0 16px;
  }
}

.issueCard {
  &Container {
    margin: 20px 0;
  }
  &Placeholder {
    @extend .issueCard;
    height: 400px;
  }
  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
  margin: 10px;
  flex: 1;
  max-width: 400px;
  min-width: 300px;
  height: 100%;
  padding-bottom: 10px;
}

.progressWrapper {
  position: relative;
  span {
    font-weight: 600;
    font-size: 11px;
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.warning {
  color: #ffb74d;
}

.delete {
  color: #ef5350;
}

.downloadLink {
  color: #7e57c2;
  text-decoration: none;
  display: block;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.image {
  max-width: 100%;
}

.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.rating {
  position: relative;
  margin: 10px auto;
  max-width: 500px;
}

.duration {
  width: 125px;
  margin-left: 10px;
}

.alias {
  width: calc(100% - 150px);
}

.steps {
  margin-top: 20px;
}

.overallRating {
  text-align: center;
  height: 40px;
  font-weight: 600;
  span {
    display: inline-flex;
    align-items: flex-end;
    color: #ffa726;
  }
}

.hoverRating {
  font-weight: 600;
  position: absolute;
  right: -30px;
  top: 13px;
}

.iconAccuracy {
  margin-right: 10px;
}


.metricToggle {
  position: relative;
  label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  input {
    color: transparent;
    opacity: 0.5;
    border-radius: 4px;
    &:focus {
      opacity: 1;
    }
  }
}

.menulist {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
  }
}

/*
.stepContainer {
  position: relative;
  width: 100%;
  margin-top: -30px;
}
*/
.stepContainer {
  display: flex;
  flex-direction: column;
  height: 100%
}

.meetingTopBar {
  flex-shrink: 0; /* Prevent shrinking */
  padding: 20px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  display: flex;
  justify-content: space-between;
  margin-right: 57px
}


/*
.meetingContainer {
  display: flex;
  flex-direction: column;
  height: 100vh; // Full viewport height
}*/
/*.meetingContainer {
  flex-grow: 1; // Allow this div to grow and take up remaining space
  display: flex;
  flex-direction: column;
}*/
.meetingContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Take up remaining space */
  overflow: auto; /* Optional: Add scrolling if content overflows */
  //background-color: lightblue;
  margin-bottom: -30px;
  margin-right: 57px
}

/*
.stepContent {
  margin-top: 66px;
  padding: 20px;
  flex-grow: 1; // Allow this div to grow and take up remaining space
  overflow: auto; // Optional: Add overflow handling if needed
}*/
.stepContent {
  flex-grow: 1; // Allow this div to grow and take up remaining space
  overflow: auto; // Optional: Add overflow handling if needed
}

.stepContentFlex {
  flex-grow: 1; // Allow this div to grow and take up remaining space
  overflow: auto; // Optional: Add overflow handling if needed
  display: flex;
  flex-direction: column;
}

/*.meetingBottomBar {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  display: flex;
  justify-content: space-between;
}*/
.meetingBottomBar {
  height: 200px; /* Default height */
  flex-grow: 1; /* Prevent shrinking */
  overflow: auto; /* Prevent overflow during resize */
  //background-color: lightgreen;
}

.userContainer, .toolsContainer {
  display: flex;
  align-items: center;
}

.exitButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.notificationBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.stepItem {
  position: relative;
  overflow: hidden;
}


.stepTimeIndicatorContainer {
  position: absolute;
  bottom: 0;
  height: 5px;
  left: 0;
  width: 100%;
  background-color: rgb(200, 200, 200); /* Background behind the progress bar */
  &Warning {
    @extend .stepTimeIndicatorContainer;
    background-color: rgba(245, 202, 12, 0.333);
  }
  &Alert {
    @extend .stepTimeIndicatorContainer;
    background-color: rgba(218, 74, 7, 0.333);
  }   
  &Complete {
    @extend .stepTimeIndicatorContainer;
    background-color: #da4907;
  }  
}

.stepTimeIndicator {
  position: absolute;
  bottom: 0;
  height: 5px;
  left: 0;
  width: 100%;
  background-color: #1e88e5;
  transition: transform 1s linear;

  &Warning {
    @extend .stepTimeIndicator;
    background-color: rgb(245, 202, 12);
  }
  &Alert {
    @extend .stepTimeIndicator;
    background-color: #da4907;
  } 
}



.stepHeader1 {
  font-size: 2em;
  font-weight: 600;
}

.stepHeader2 {
  font-size: 1.8em;
  font-weight: "bold";
}

.icon {
  margin-bottom: 10px;
  margin-left: auto;
  color: #fff;
}



.stepLabel {
  opacity: 0.65;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.tableLabel {
  opacity: 0.65;
}

/*
.icon {
  margin-bottom: 10px;
  &Left {
    margin-left: auto;
    background-color: #9ccc65;
    &:hover {
      background-color: #7cb342;
    }
  }
  &Accuracy {
    margin-right: 5px;
  }
}*/

.card {
  position: relative;
  overflow: visible;
  min-height: 100px;
}

.title {
  text-transform: capitalize;
}

/*.cardContent {
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}*/

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
}
.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;
  // max-height: 75vh;
  min-height: 288px;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}



.virtualValue {
  width: 100%;
  input {
    text-align: left;
  }
  div {
    width: 85%;
    min-width: 70px;
    margin: 0 auto;
    border-radius: 4px;

   /* &::before {
      display: none;
    }
    &::after {
      display: none;
    }
      */
  }
  &Above {
    @extend .virtualValue;
    input {
      background-color: rgba(102, 187, 106, 0.3);
    }
  }
  &Below {
    @extend .virtualValue;
    input {
      background-color: rgba(239, 83, 80, 0.3);
    }
  }

  &Text {
    font-weight: 600;
  }
}

.table {
  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.firstCell {
  padding-left: 50px;
  width: 1px;
}

.virtualCell {
  padding: 0 0px;
  background-color: rgba(0, 0, 0, 0.065);
  opacity: 0.65;
  font-style: oblique;
}

.roundedToggle {
  position: relative;
  label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  input {
    // color: transparent;
    opacity: 1;
    border-radius: 4px;
    &:focus {
      opacity: 1;
      color: #000;
    }
    &:disabled {
      opacity: 1;
      color: #000;
    }
  }
}

.left {
  margin-left: auto;
  &Icon {
    @extend .left;
    background-color: #26a69a;
    &:hover {
      background-color: #00897b;
    }
  }
}

.searchField {
  margin: 4px;
}

.menuButton {
  margin: 4px;
  &Color {
    margin: 4px;
    background-color: #7e57c2;
    &:hover {
      background-color: #4527a0;
    }
  }
}

.cellButton {
  margin: 4px;
  padding: 2px 4px;
  font-size: 0.5rem;
  min-width: 40px;
  &Color {
    margin: 4px;
    padding: 2px 1px;
    font-size: 0.5rem;
    min-width: 40px;    
    background-color: #7e57c2;
    &:hover {
      background-color: #4527a0;
    }
  }
}
