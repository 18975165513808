.container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #673ab7;
  overflow: hidden;
}

.splash {
  &Overlay {
    display: flex;
    align-items: center;
    padding: 10px;
    img {
      height: 50px;
      margin-right: 20px;
    }
  }

  &Title {
    color: #fff;
    font-weight: 700;
  }
}

.svg {
  position: absolute;
  width: 95%;
  max-width: 1100px;
  top: 75px;
  left: 0;
  @media screen and (max-width: 960px) {
    width: auto;
    height: 100%;
    left: -100px;
    top: 150px;
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 400px;
  height: 100%;
  border-radius: 20px 0 0 20px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    height: 400px;
  }
}

.version {
  margin-top: 20px;
}

.linkContainer {
  display: flex;
  justify-content: center;
}

.link {
  margin: 0 4px;
  font-size: 0.6rem;
}
