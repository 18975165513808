.card {
  position: absolute;
  width: 90%;
  left: 50%;
  top: 0;
  transform: translate(-50%, -15%);
  z-index: 3;
}

.cardVertical {
  @extend .card;
  width: auto;
  height: 90%;
  left: 0;
  top: 50%;
  transform: translate(-15%, -50%);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 4;
}

.content {
  padding-bottom: 16px !important;
  color: #fff;
  display: flex;
  align-items: center;
}
