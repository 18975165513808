

.quarterBlock {
    background-color: white;
    border: 2px solid black;
    width: 50px;
    height: 50px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size:18px;
    text-align: center;
    box-shadow: 1px 1px 5px grey; 

    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center; 

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none; // Remove the right border to avoid doubling
      }
  
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
  
      &:not(:first-child):not(:last-child) {
        border-right: none; // Remove the right border to avoid doubling
      }

}

.yearLabel {
    text-align: right;
    flex-grow: 1;
    max-width: 60px;
    margin: 2px 0 0 0; 
    font-weight: 500;
    color: purple;
    line-height: 1.2;
    font-size: 0.8571428571428571rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;    
}

.quarterBlockContainer {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.quarterContainer {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;    
}


.container {
    display: flex;
    flex-wrap: wrap;
    gap: 0px; // Space between cells
    justify-content: flex-start; // Align cells to the left
  
    .cell {
      width: 210px; // Fixed width for each cell
      height: 100%;
      flex-shrink: 0; // Prevent cells from shrinking

      @media (max-width: 484px) {
        // Container for 1 cell per row
          width: 270px; // Total width for 1 cell
      }

    }
  
    @media (min-width: 1350px) {
      // Container for 4 cells per row
        max-width: calc(5 * 210px + 4 * 16px); // Total width for 5 cells and 4 gaps
        width: calc(5 * 210px + 4 * 16px); // Total width for 5 cells and 4 gaps
    }
  
    @media (max-width: 1349px) and (min-width: 485px) {
      // Container for 2 cells per row
         max-width: calc(2 * 210px + 1 * 16px); // Total width for 2 cells and 1 gap
        width: calc(2 * 210px + 1 * 16px); // Total width for 2 cells and 1 gap
    }
  
    @media (max-width: 484px) {
      // Container for 1 cell per row
        max-width: 270px; // Total width for 1 cell
    }
  }

  .parent {
    width:100%;
    height:100%;
  }