.flex {
  display: flex;
  align-items: center;
  &Between {
    @extend .flex;
    justify-content: space-between;
  }
}

.departmentWrapper {
  margin-left: 20px;
}

.label {
  opacity: 0.65;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    display: none;
  }
}
