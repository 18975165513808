.card {
  position: relative;
  overflow: visible;
  min-height: 288px;
}

.title {
  text-transform: capitalize;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  margin-left: 55px;
}
.cardContent {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;
  // max-height: 75vh;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.table {
  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  thead tr th {
    white-space: nowrap;
  }
}

.label {
  opacity: 0.65;
}

.delete {
  color: #ef5350;
}

.flex {
  display: flex;
  align-items: center;
  &Center {
    @extend .flex;
    justify-content: center;
  }
  &Relative {
    @extend .flex;
    justify-content: center;
    position: relative;
  }
}

.author {
  display: flex;
  align-items: center;
  margin: 1em 1em 0 1em;
}

.left {
  margin: 4px;
}

.firstCell {
  padding-left: 50px;
}
