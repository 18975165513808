.status {
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  margin-right: 5px;
}
