.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
  &Padded {
    @extend .label;
    margin-left: 16px;
  }
}

.card {
  position: relative;
  overflow: visible;
  padding-top: 50px;
  color: #fff;
  margin: 30px 0;
}

.subtitle {
  opacity: 0.65;
  margin-left: 10px;
  font-style: oblique;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.body {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.87);
}

.icon {
  margin: 0 10px 0 0;
}

.placeholder {
  text-transform: capitalize;
}

.flex {
  display: flex;
  align-items: center;
  &End {
    @extend .flex;
    justify-content: flex-end;
  }
  &Paragraph {
    @extend .flex;
    margin-bottom: 16px;
  }
  &Between {
    @extend .flex;
    justify-content: space-between;
  }
}

.avatar {
  background-color: #d3d3d3;
  width: 200px;
  height: 200px;
  font-size: 100px;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  img {
    object-fit: contain;
  }
}

.divider {
  margin-top: 16px;
}

.editButton {
  margin-left: auto;
  text-align: right;
}

.avatarWrapper {
  position: relative;
}

.editAvatar {
  position: absolute;
  bottom: 0;
  left: 42px;
  button {
    color: #000;
  }
}

.input {
  display: none;
}

.delete {
  color: #ef5350;
}

.draggable {
  cursor: grab;
}

.gridHalves {
  &:first-of-type {
    padding-right: 10px;
  }

  &:last-of-type {
    padding-left: 10px;
  }
}

.dialogContent {
  overflow-y: hidden;
}
