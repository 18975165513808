.container {
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.emphasis {
  font-weight: 700;
  text-transform: capitalize;
}

.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.flex {
  display: flex;
  align-items: center;
  &Between {
    @extend .flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  &Center {
    @extend .flex;
    justify-content: center;
    gap: 8px;
  }
}

.icon {
  margin: 0 5px 0 15px;
  @media screen and (max-width: 600px) {
    margin: 0 5px 0 0;
  }
  &:nth-child(4) {
    margin-left: 15px;
  }
}
